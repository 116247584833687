<template>
  <div>
    <div
      v-if="this.$route.query.id && loaded"
    >
      <b-row class="mt-2">
        <b-col
          md="12"
          :lg="(emmas.length !== 0) ? 6 : 12"
        >
          <alarm-statistic-card :alarm-amount="alarmNumbers" />
        </b-col>
        <b-col @click="scrollToTable">
          <offline-emmas-card
            v-if="emmas.length !== 0"
            :offline-emmas="offlineEmmas.length"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          sm="12"
          :md="(suborganizations.length <= 5) ? 6 : 12"
        >
          <offline-emmas-time-graph
            v-if="emmas.length !== 0"
            :key="updateKeyTimeChartSingle"
            :payload="datasetTimeChartSingleData"
          />
          <div v-else>
            <statistic-card-horizontal
              icon="AlertTriangleIcon"
              color="danger"
              :statistic="$t('countyNoEmmas')"
            />
          </div>
        </b-col>
        <b-col
          sm="12"
          :md="(suborganizations.length <= 5) ? 6 : 12"
        >
          <offline-emmas-bar-graph
            v-if="barChartSuborganizationData.length !== 0"
            :key="updateKeyBarChart"
            :payload="barChartSuborganizationData"
            scope="organization"
            :title="$t('offlineEmmasPerCounty')"
          />
          <div v-else>
            <statistic-card-horizontal
              icon="AlertTriangleIcon"
              color="danger"
              :statistic="$t('organizationNoCounties')"
            />
          </div>
        </b-col>
      </b-row>
      <b-row
        v-if="emmas.length !== 0"
      >
        <b-col
          lg="4"
          cols="12"
        >
          <active-network-type-graph
            :emmas="emmas"
            :online-emmas="onlineEmmas"
            :offline-emmas="offlineEmmas"
          />
        </b-col>
        <b-col
          lg="8"
          cols="12"
        >
          <offline-emmas-time-graph
            v-if="datasetTimeChartMultipleData.length !== 0"
            :key="updateKeyTimeChartMultiple"
            :payload="datasetTimeChartMultipleData"
          />
        </b-col>
      </b-row>
      <b-row
        v-if="emmas.length !== 0"
      >
        <b-col
          ref="emma-table"
        >
          <emma-table-good-table
            :show-suborganisations="true"
            :show-clusters="true"
            :show-local-groups="true"
            :emmas="emmas"
          />
        </b-col>
      </b-row>
    </div>
    <div v-else>
      <selection-cards
        :options="$store.state.organizations.organizations"
        scope="organization"
      />
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import SelectionCards from '@/components/management/SelectionCards.vue'
import OfflineEmmasCard from '@/components/management/cards/OfflineEmmasCard.vue'
import OfflineEmmasTimeGraph from '@/components/management/graphs/OfflineEmmasTimeGraph.vue'
import OfflineEmmasBarGraph from '@/components/management/graphs/OfflineEmmasBarGraph.vue'
import EmmaTableGoodTable from '@/components/management/tables/EmmaTableGoodTable.vue'
import AlarmStatisticCard from '@/components/management/cards/AlarmStatisticCard.vue'
import statisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import ActiveNetworkTypeGraph from '@/components/management/graphs/ActiveNetworkTypeGraph.vue'

export default {
  name: 'OrganizationDashboard',
  components: {
    BRow,
    BCol,
    EmmaTableGoodTable,
    OfflineEmmasCard,
    OfflineEmmasTimeGraph,
    OfflineEmmasBarGraph,
    AlarmStatisticCard,
    SelectionCards,
    statisticCardHorizontal,
    // Calendar,
    ActiveNetworkTypeGraph,
  },
  data() {
    return {
      autoUpdate: undefined,
      updateKeyBarChart: 0,
      updateKeyTimeChartSingle: 0,
      updateKeyTimeChartMultiple: 0,
      currentTimestamp: Date.now(),
    }
  },
  computed: {
    loaded() {
      // if (this.datasetTimeChartSingleData.length !== 0 && this.datasetTimeChartMultipleData.length !== 0 && this.barChartSuborganizationData.length !== 0) {
      if (this.datasetTimeChartSingleData.length !== 0) {
        return true
      }
      return false
    },
    selectedOrg() {
      return this.$store.getters['organizations/getOrgById'](parseInt(this.$route.query.id, 10))
    },
    suborganizations() {
      if (this.selectedOrg) {
        return this.$store.getters['suborganizations/getSuborganizationByOrganizationId'](this.selectedOrg.id, 10)
      }
      return {}
    },
    emmas() {
      if (this.selectedOrg) {
        return this.$store.getters['emmas/getEmmasByOrganizationId'](this.selectedOrg.id, 10)
      }
      return {}
    },
    offlineEmmas() {
      return this.emmas.filter(emma => this.getTimeSinceHeartbeat(emma.timestamp) > this.$store.state.emmas.secondsBeforeOffline || emma.timestamp === undefined)
    },
    onlineEmmas() {
      return this.emmas.filter(emma => this.getTimeSinceHeartbeat(emma.timestamp) <= this.$store.state.emmas.secondsBeforeOffline && emma.timestamp !== undefined)
    },
    barChartSuborganizationData() {
      const dataset = []
      for (let i = 0; i < this.suborganizations.length; i += 1) {
        const suborganizationEmmas = this.$store.getters['emmas/getEmmasBySuborganizationId'](this.suborganizations[i].id)
        const suborganizationEmmasOffline = suborganizationEmmas.filter(emma => this.getTimeSinceHeartbeat(emma.timestamp) > this.$store.state.emmas.secondsBeforeOffline || emma.timestamp === undefined)
        dataset.push({
          name: this.suborganizations[i].name,
          dataset: suborganizationEmmasOffline.length,
          totalEmmas: suborganizationEmmas.length,
        })
      }
      return dataset
    },
    alarmNumbers() {
      return Number.parseInt(this.selectedOrg.alarmHistory, 10)
    },
    datasetTimeChartSingleData() {
      if (this.selectedOrg !== undefined) {
        return [{ name: this.selectedOrg.name, dataset: this.selectedOrg.offlineEmmaHistory, totalEmmas: this.emmas.length }]
      }
      return []
    },
    datasetTimeChartMultipleData() {
      const dataset = []
      for (let i = 0; i < this.suborganizations.length; i += 1) {
        dataset.push(
          {
            name: this.suborganizations[i].name,
            dataset: this.suborganizations[i].offlineEmmaHistory,
            totalEmmas: this.$store.getters['emmas/getEmmasBySuborganizationId'](this.suborganizations[i].id).length,
          },
        )
      }
      return dataset
    },
  },
  watch: {
    barChartSuborganizationData(oldVal, newVal) {
      if (JSON.stringify(oldVal) !== JSON.stringify(newVal)) {
        this.updateKeyBarChart += 1
      }
    },
    datasetTimeChartSingleData(oldVal, newVal) {
      if (JSON.stringify(oldVal) !== JSON.stringify(newVal)) {
        this.updateKeyTimeChartSingle += 1
      }
    },
    datasetTimeChartMultipleData(oldVal, newVal) {
      if (JSON.stringify(oldVal) !== JSON.stringify(newVal)) {
        this.updateKeyTimeChartMultiple += 1
      }
    },
  },
  async mounted() {
    this.autoUpdate = setInterval(this.setUpdateKey, 1000)
    if (this.$store.state.organizations.organizations.length === 1 && !this.$route.query.id) {
      await this.$router.push(`/dashboard/organization?id=${this.$store.state.organizations.organizations[0].id}`)
    }
    this.setPageTitle()
  },
  beforeDestroy() {
    clearInterval(this.autoUpdate)
  },
  updated() {
    this.setPageTitle()
  },
  methods: {
    setPageTitle() {
      if (this.selectedOrg !== undefined) {
        this.$store.commit('app/SET_PAGE_TITLE', this.selectedOrg.name)
      } else {
        this.$store.commit('app/SET_PAGE_TITLE', this.$t('organizations'))
      }
    },
    scrollToTable() {
      this.$refs['emma-table'].scrollIntoView({ behavior: 'smooth' })
    },
    async setUpdateKey() {
      this.currentTimestamp = Date.now()
    },
    getTimeSinceHeartbeat(heartbeat) {
      const differenceMilliseconds = this.currentTimestamp - heartbeat
      const differenceSeconds = Math.floor(differenceMilliseconds / 1000)
      return differenceSeconds
    },
  },
}
</script>

<style scoped>

</style>
